<template>
  <div>
    <div class="border border-solid border-blue-200">
      <datatable
        :data="loans.data"
        :columns="loans.columns"
        :query="searchQuery"
        :loading="loans.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="
          `${this.$baseurl}/admin/personal/cards/tangerine/repayments/batch`
        "
        :search-field="searchField"
        :actions="actions"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <modal
      className="w-full md:w-6/10 lg:w-6/10 xl:w-4/10 pt-10 px-10"
      ref="payNowModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-12">
        Confirm Payment
      </h4>

      <div>
        <template v-if="getFormError(repaymentError)">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(repaymentError)
            }}</span>
          </div>
        </template>

        <template v-if="selectedRepayment">
          <section class="mb-5">
            <div class="mb-2 text-gray-500">Payment Details:</div>

            <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200">
              <div class="col-span-1 p-4">
                <div class="opacity-75 text-sm font-medium mb-1">
                  Batch No.
                </div>
                <div
                  class="text-gray-500 text-sm font-light flex flex-row items-center"
                >
                  <ion-icon
                    name="pricetag-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ selectedRepayment.batch }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
            >
              <div class="col-span-1 p-4">
                <div class="opacity-75 text-sm font-medium mb-1">
                  Due Date
                </div>
                <div
                  class="text-gray-500 text-sm font-light flex flex-row items-center"
                >
                  <ion-icon
                    name="calendar-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ selectedRepayment.due_date }}
                </div>
              </div>
              <div
                class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
              >
                <div class="opacity-75 text-sm font-medium mb-1">
                  No. of Repayments
                </div>
                <div
                  class="text-gray-500 text-sm font-light flex flex-row items-center"
                >
                  <ion-icon
                    name="briefcase-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  {{ selectedRepayment.number_of_repayments }}
                </div>
              </div>
            </div>

            <div
              class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
            >
              <div class="col-span-1 p-4">
                <div class="opacity-75 text-sm font-medium mb-1">
                  Interest Amount
                </div>
                <div
                  class="text-gray-500 text-sm font-light flex flex-row items-center"
                >
                  <ion-icon
                    name="wallet-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  ₦{{ selectedRepayment.total_interest_amount | currency }}
                </div>
              </div>
              <div
                class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
              >
                <div class="opacity-75 text-sm font-medium mb-1">
                  Principal Amount
                </div>
                <div
                  class="text-gray-500 text-sm font-light flex flex-row items-center"
                >
                  <ion-icon
                    name="wallet-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  ₦{{ selectedRepayment.total_principal_amount | currency }}
                </div>
              </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200">
              <div class="col-span-1 p-4">
                <div class="opacity-75 text-sm font-medium mb-1">
                  Repayment Amount
                </div>
                <div
                  class="text-black-500 text-sm font-light flex flex-row items-center font-bold"
                >
                  <ion-icon
                    name="wallet-outline"
                    class="text-lg mr-2"
                  ></ion-icon>
                  ₦{{ selectedRepayment.total_repayment_amount | currency }}
                </div>
              </div>
            </div>
          </section>
        </template>

        <div class="text-center">
          <button
            @click.prevent="payTangerineLifeByBatch"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="false"
          >
            <template v-if="clearing">Paying...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    },
    lenders: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      checkmark: require('@/assets/checkmark-base.svg'),
      loans: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: 'batch',
            th: 'Batch'
          },
          {
            name: 'due_date',
            th: 'Due Date',
            render: (repayments, due_Date) =>
              this.$moment(due_Date).format('YYYY-MM-DD')
          },
          {
            name: 'total_interest_amount',
            th: 'Total Interest',
            render: (repayments, total_interest_amount) =>
              `₦ ${this.$options.filters.currency(total_interest_amount)}`
          },
          {
            name: 'total_principal_amount',
            th: 'Total Principal',
            render: (repayments, total_principal_amount) =>
              `₦ ${this.$options.filters.currency(total_principal_amount)}`
          },
          {
            name: 'total_repayment_amount',
            th: 'Total Repayment',
            render: (repayments, total_repayment_amount) =>
              `₦ ${this.$options.filters.currency(total_repayment_amount)}`
          },
          {
            name: 'total_amount_paid',
            th: 'Total Paid',
            render: (repayments, total_amount_paid) =>
              `₦ ${this.$options.filters.currency(total_amount_paid || 0)}`
          },
          {
            name: 'number_of_repayments',
            th: 'Number of Repayments'
          }
        ]
      }),
      actions: [
        {
          text: 'repay now',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openRepayNowModal
        }
      ],
      selectedRepayment: null,
      repaymentError: {},
      clearing: false
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    getLender() {
      const lender = this.lenders.data.find(
        lender => lender.name === 'TangerineLife'
      );
      return lender?.id || null;
    }
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    removeError() {
      this.repaymentError = '';
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    getLoans() {
      this.$refs.table.loadAjaxData();
    },
    getRepayments() {
      this.$refs.table.loadAjaxData();
    },
    openRepayNowModal(repayment) {
      this.selectedRepayment = repayment;
      this.$refs.payNowModal.open();
    },
    async payTangerineLifeByBatch() {
      this.clearing = true;
      await this.sendRequest('admin.cards.transactions.payTangerineBatch', {
        data: {
          lender: this.getLender,
          batch: this.selectedRepayment.batch,
          dueDate: this.selectedRepayment.due_date,
          repaymentCount: this.selectedRepayment.number_of_repayments
        },
        success: () => {
          this.$success({
            title: 'Repayment Marked as Paid!',
            body: 'You will be sent an email if there was an error',
            button: 'Okay'
          });
          this.getRepayments();
          this.$refs.payNowModal.close();
        },
        error: error => {
          console.log(9, error);
          // this.repaymentError["error"] = error
        }
      });
      this.clearing = false;
    }
  }
};
</script>
